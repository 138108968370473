// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-custom-list-js": () => import("./../../../src/pages/custom-list.js" /* webpackChunkName: "component---src-pages-custom-list-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-list-js": () => import("./../../../src/pages/instagram-list.js" /* webpackChunkName: "component---src-pages-instagram-list-js" */),
  "component---src-pages-lists-js": () => import("./../../../src/pages/lists.js" /* webpackChunkName: "component---src-pages-lists-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-request-sample-js": () => import("./../../../src/pages/request-sample.js" /* webpackChunkName: "component---src-pages-request-sample-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-influencer-marketing-js": () => import("./../../../src/pages/resources/influencer-marketing.js" /* webpackChunkName: "component---src-pages-resources-influencer-marketing-js" */),
  "component---src-pages-return-policy-js": () => import("./../../../src/pages/return-policy.js" /* webpackChunkName: "component---src-pages-return-policy-js" */),
  "component---src-pages-sample-js": () => import("./../../../src/pages/sample.js" /* webpackChunkName: "component---src-pages-sample-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

