module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-PMTFRXX8JY","AW-614082583"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"influencerlist-landing-page","short_name":"IL","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/logos/list.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd8dda792d0627c0504d28562cbc2262"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
